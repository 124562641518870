import Modal from "./Modal"
import {useState} from 'react'
import { doc, updateDoc } from "firebase/firestore";
import {db} from './firebase'
import { EditorState, convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

function EditPost({open, onClose, toEditTitle, toEditText, id}) {

  const [title, setTitle] = useState(toEditTitle)
  const [text, setText] = useState(toEditText)

  /** React Draft.js configuration**/
  const [editorState, setEditorState] = useState(
    () => EditorState.createEmpty(),
  );
  const  [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  }
  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  }
  const createMarkup = (html) => {
      return  {
          __html: DOMPurify.sanitize(html)
      }
  }

  /* function to update firestore */
  const handleUpdate = async (e) => {
    e.preventDefault()
    const postDocRef = doc(db, 'posts', id)
    try{
      await updateDoc(postDocRef, {
        title: title,
        text: createMarkup(convertedContent)
      })
      onClose()
    } catch (err) {
      alert(err)
    }
    
  }

  return (
    <Modal modalLable='Edit Task' onClose={onClose} open={open}>
      <form onSubmit={handleUpdate} className='editTask'>
        <input type='text' name='title' onChange={(e) => setTitle(e.target.value.toUpperCase())} value={title}/>
        <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class"
            toolbarClassName="toolbar-class"
        />        
        <button type='submit'>Edit</button>
      </form> 
    </Modal>
  )
}

export default EditPost
