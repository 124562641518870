import Modal from "./Modal"
import {useState} from 'react'
import {db} from './firebase'
import {collection, addDoc, Timestamp} from 'firebase/firestore'
import { EditorState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


function AddPost({onClose, open}) {

    const [title, setTitle] = useState('')
    const [text, setText] = useState('')


  /** React Draft.js configuration**/
    const [editorState, setEditorState] = useState(
      () => EditorState.createEmpty(),
    );
    const  [convertedContent, setConvertedContent] = useState(null);
    const handleEditorChange = (state) => {
      setEditorState(state);
      convertContentToHTML();
    }
    const convertContentToHTML = () => {
      let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
      setConvertedContent(currentContentAsHTML);
    }
    const createMarkup = (html) => {
        return  {
            __html: DOMPurify.sanitize(html)
        }
    }


  /* function to add new post to firestore */
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await addDoc(collection(db, 'posts'), {
        title: title,
        text: createMarkup(convertedContent),
        created: Timestamp.now()
      })
      onClose()
    } catch (err) {
      alert(err)
    }
  }

  return (
    <Modal modalLable='Add Post' onClose={onClose} open={open}>
      <form onSubmit={handleSubmit} className='addPost' name='addPost'>
        <input
            className="input-field"
            type='text' 
            name='title' 
            onChange={(e) => setTitle(e.target.value)} 
            value={title}
            placeholder='Enter title'/>
 
                                 <Editor
                            editorState={editorState}
                            onEditorStateChange={handleEditorChange}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                        />
        <button type='submit'>Done</button>
      </form> 
    </Modal>
  )
}

export default AddPost
