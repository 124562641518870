import Modal from "./Modal"
import parse from 'html-react-parser';

function PostItem({onClose, open, title, text}) {

  return (
    <Modal onClose={onClose} open={open}>
      <div className='taskItem'>
        <h2>{title}</h2>
        <p>{ parse(text.__html) }</p>
      </div>
    </Modal>
  )
}

export default PostItem
