import {useState} from 'react'
import { doc, updateDoc, deleteDoc} from "firebase/firestore";
import {db} from './firebase'
import parse from 'html-react-parser';
import PostItem from './PostItem';
import EditPost from './EditPost';
import ModeIcon from '@mui/icons-material/Mode';


function Post({id, title, text}) {

  const [open, setOpen] = useState({edit:false, view:false})

  const handleClose = () => {
    setOpen({edit:false, view:false})
  }

  /* function to delete a document from firstore */ 
  const handleDelete = async () => {
    const postRef = doc(db, 'posts', id)
    try{
      await deleteDoc(postRef)
    } catch (err) {
      alert(err)
    }
  }

  return (
    <div className='p-4 mb-6 bg-gray-100 shadow-sm'>
        <h2>{title}</h2>
        { parse(text.__html) }
        <div className='task__buttons'>
          <div className='task__deleteNedit'>
            <button 
              className='btn mr-2'
              onClick={() => setOpen({...open, view: true})}>
              View
            </button>            
            <button 
              className='btn btn-success mr-2' 
              onClick={() => setOpen({...open, edit : true})}>
              Edit
            </button>
            <button 
              className='btn btn-danger' 
              onClick={handleDelete}>
              Delete
            </button>
          </div>
        </div>

        {open.view &&
            <PostItem 
            onClose={handleClose} 
            title={title} 
            text={text} 
            open={open.view} />
        }
      
        {open.edit &&
            <EditPost 
            onClose={handleClose} 
            toEditTitle={title} 
            open={open.edit}
            id={id} />
        }


    </div>
  )
}

export default Post