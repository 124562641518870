import React from 'react'
import AddPost from './AddPost'
import { useState } from 'react'

function Header() {
    const [openAddModal, setOpenAddModal] = useState(false)

    return (
        <div>
            <div className="w-100 bg-gray-900 flex align-middle py-2 px-4 shadow-xl">
                <div className='flex w-1/2'>
                    <h4 className='align-middle block text-white'>Konia WiFi Portal</h4>
                </div>
                <div className='w-1/2 text-right'>
                    <button
                        className='btn btn-success mr-2' 
                        onClick={() => setOpenAddModal(true)}>
                        Add Post
                    </button>
                    <button className="btn text-white border-white">Log out</button>
                    {openAddModal &&
                    <AddPost onClose={() => setOpenAddModal(false)} open={openAddModal}/>
                    }
                </div>
            </div>
        </div>
    )
}

export default Header
