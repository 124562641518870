import React from 'react'
import { useLocation  } from 'react-router-dom';
import axios from 'axios';


export default function Home() {

    const useQuery = () => {
        const { search } = useLocation();
      
        return React.useMemo(() => new URLSearchParams(search), [search]);
    }

    let query = useQuery();

    const sip = query.get('sip');
    const url = query.get('url');
    const proxy = query.get('proxy');
    const uip = query.get('uip');
    const clientMac = query.get('client_mac');

    /*
    console.log(query.get("sip"));
    console.log(query.get("url"));
    console.log(query.get("proxy"));
    console.log(query.get("uip"));
    console.log(query.get("client_mac"));
    */
/*
    const formData = {
        'url': url,
        'proxy': proxy,
        'uip': uip,
        'client_mac': clientMac,
        'username': 'ruckusradius',
        'password': '$p3ctRumc00L',
    }   
*/
    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('https://' + sip + ':9997/SubscriberPortal/hotspotlogin', {
            'url': url,
            'proxy': proxy,
            'uip': uip,
            'client_mac': clientMac,
            'username': 'ruckusradius',
            'password': '$p3ctRumc00L',
          })
          .then(function (response) {
            console.log(response);
          })
          .catch(function (error) {
            console.log(error);
          });
        /*
        axios.post({
            method: "post",
            url: 'https://ruckuscontroller.spectrum.com.cy:9997/SubscriberPortal/hotspotlogin',
            data: {
                'url': url,
                'proxy': proxy,
                'uip': uip,
                'client_mac': clientMac,
                'username': 'ruckusradius',
                'password': '$p3ctRumc00L',
            },
            headers: { "Content-Type": "multipart/form-data" },
        })
          .then(res => {
            console.log(res);
        })
    */
      }

    console.log();

    


    //const [sip, setSip] = useState('');
    //const [param, setParam] = useState('');
    //const [valuePair, setValuePair] = useState('');
    //const [formAction, setFormAction] = ['<form method=POST action="http://' + sip + ':9997/SubscriberPortal/hotspotlogin">']
    //const [formUrl, setFormUrl] = ['<input type="hidden" name="url" value="'+get_param("url")+'" />']

/*

    function get_param(name)
      {
          if (window.location.href.indexOf("?") >= 0) 
          { 
              var query=window.location.href.split("?")[1];
              var params=query.split("&");
              for (var i = 0; i < params.length; i ++) {
                   value_pair=params[i].split("=");
                  if (value_pair[0] == name)
                       return unescape(value_pair[1]);
              }
          }
          return "";
      }

      function get_sip() {
          var sip = get_param("sip");
         //var sip = "172.21.144.65"; -----> if you need to static ip, write the ip here
      
      
          if (sip.indexOf(":") < 0) { // IPv4 address
              return sip;
          } else {                    // IPv6 address
              return '[' + sip + ']';
          }
      }

      
      Username:<input type="text" name="username" value="ruckusradius">
        `Password:<input readonly type="password" name="password" value="$p3ctRumc00L">

    document.write('<form method=POST action="http://' + get_sip() + ':9997/SubscriberPortal/hotspotlogin">');
    document.write('<input type="hidden" name="url" value="'+get_param("url")+'" />');
    document.write('<input type="hidden" name="proxy" value="'+get_param("proxy")+'" />');
    document.write('<input type="hidden" name="uip" value="'+get_param("uip")+'" />');
    document.write('<input type="hidden" name="client_mac" value="'+get_param("client_mac")+'" />');   
    
    */
    return (
        <div>
            <div className="logo-wrapper"><img style={{maxHeight:'80vh',}} className="max-h-96 mx-auto" id="wifi4eubanner" src=""/></div>
                <button  className="btn btn-success" onClick={handleSubmit}>Connect</button>
        </div>
    )
}
