import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Editor from './Editor'
import Home from './Home';



function App() {
  return (
          <div className="App min-h-screen bg-gray-300">
              <Router>
                <Routes>
                    <Route path="/" element={<Home/>}/>
                    <Route path="/editor" element={<Editor/>}/>
                </Routes>
              </Router>
        </div>
  );
}

export default App;
