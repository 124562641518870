import React from 'react'
import {useState, useEffect} from 'react'
import {collection, query, orderBy, onSnapshot} from "firebase/firestore"
import {db} from './firebase'
import Post from './Post'
import AddPost from './AddPost'
import Header from './Header'

export default function Editor() {

    const [openAddModal, setOpenAddModal] = useState(false)
    const [posts, setPosts] = useState([])

    useEffect(() => {
        const postsRef = query(collection(db, 'posts'), orderBy('created', 'desc'))
        onSnapshot(postsRef, (snapshot) => {
          setPosts(snapshot.docs.map(doc => ({
            id: doc.id,
            data: doc.data()
          })))
        })
    },[])


    return (
            <div>
                <Header/>
                <div className='container mx-auto p-10'>
                    <div>
                        <div className='taskManager__tasks'>

                            {posts.map((post) => (
                            <Post
                                id={post.id}
                                key={post.id}
                                title={post.data.title} 
                                text={post.data.text}
                            />
                            ))}
                        </div>
                    </div>

                    {openAddModal &&
                    <AddPost onClose={() => setOpenAddModal(false)} open={openAddModal}/>
                    }
                </div>
            </div>
    )
}
